<template>
  <v-container
    id="agents"
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in mainTabs"
        :key="i"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

      <v-tabs-items
        v-model="mainTab"
        class="transparent"
      >
        <v-tab-item>
          <agents />
        </v-tab-item>

        <v-tab-item>
          <add-agent />
        </v-tab-item>
      </v-tabs-items>
    </base-material-tabs>
  </v-container>
</template>

<script>
  export default {
    components: {
      Agents: () => import('./Agents'),
      AddAgent: () => import('./AddAgent.vue'),
    },
    data: () => ({
      mainTab: 0,
      mainTabs: [
        { text: 'Agents', icon: 'mdi-face-agent' },
        { text: 'Add Agent', icon: 'mdi-plus' },
      ],
    }),
  }
</script>
